









































import { DownloadRequest as DownloadRequestModel, DownloadStatus, UserProfile } from '@ht-lib/accounts-models'
import { DownloadRequest, DownloadRequestWithData } from '@ht-web/firebase-adapter'
import { format } from 'date-fns'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { downloadArtifact } from '../ts/download'
import { getUserWithStore } from '../ts/userStore'

interface UserMap {
  [id: string]: UserProfile
}

@Component({
  name: 'DownloadRequestTable'
})
export default class DownloadRequestTable extends Vue {
  @Prop({ required: true, type: String }) contextId: string
  @Prop({ default: true }) hideIfEmpty: boolean

  loading = false
  requests: DownloadRequestModel[] = []

  users: UserMap = {}
  unListen: (() => void) | undefined

  showDownload (req: DownloadRequestModel) {
    return req.status === DownloadStatus.FINISHED
  }

  showRetry (req: DownloadRequestModel) {
    return req.status === DownloadStatus.ERRORED
  }

  async retry (req: DownloadRequestModel): Promise<void> {
    return DownloadRequest.byId(req.id).retry()
  }

  getUserName (id: string) {
    return this.users[id]?.displayName || ''
  }

  percentFinished (req: DownloadRequestModel) {
    return Math.round((req.imageFinishedCount / req.imageCount) * 100)
  }

  formatDate (timestamp: DownloadRequestModel['startTime'] | DownloadRequestModel['endTime']): string {
    if (timestamp == null) return 'N/A'
    return format(timestamp.toDate(), 'HH:mm dd MMM')
  }

  download (req: DownloadRequestModel) {
    downloadArtifact(req.id)
  }

  beforeDestroy (): void {
    this.unListen?.()
  }

  @Watch('contextId', { immediate: true })
  updateSnapshot (): void {
    console.log('Updating Download Request table', this.contextId)
    this.loading = true
    this.unListen?.()
    this.unListen = DownloadRequest
      .byContextId(this.contextId)
      .listen(dr => {
        const work = dr
          .sort((a, b) => b.startTime.seconds - a.startTime.seconds)
          .map(async dr => this.processDownloadRequestRow(dr))

        Promise.all(work)
          .then(drs => {
            // splice is reactve in vue
            // We have to do it this way because some elements may have been removed and some added
            // The date ordering means it's easier to just replace all elements than it is to try and work out where new ones go and splice individually
            // There's unlikely to be enoug items here that performance is a concern.
            this.requests.splice(0)
            this.requests.splice(0, 0, ...drs)
          })
          .finally(() => { this.loading = false })
      })
  }

  async processDownloadRequestRow (dr: DownloadRequestWithData): Promise<DownloadRequestWithData> {
    try {
      if (this.users[dr.userId] === undefined) {
        const user = await getUserWithStore(dr.userId)
        Vue.set(this.users, user.id, user)
      }
    } catch (e) {
      console.error('Error getting user', e)
    }
    return dr
  }
}
