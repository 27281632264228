import { DownloadRequestFileNameKey } from '@ht-lib/accounts-models'
import 'firebase/compat/storage'
import { app } from '../firebase'

const bucketName = process.env.NODE_ENV === 'production' ? 'ht-uk-download-artifacts-prod' : 'ht-uk-download-artifacts-test'
const requests = app.firestore().collection('downloadRequests')

export type FileNameUserFormatted = 'Ref' | 'Pupil ID' | 'Long Ref'

export const fileNameOptions: Record<FileNameUserFormatted, DownloadRequestFileNameKey> = {
  Ref: 'ref',
  'Pupil ID': 'pupilId',
  'Long Ref': 'longRef'
}
export async function downloadArtifact (id: string) {
  const objectUrl = `gs://${ bucketName }/${ id }`
  console.log('Downloading artifact with url ', objectUrl)
  const url = await app.storage().refFromURL(`gs://${ bucketName }/${ id }.zip`).getDownloadURL()
  window.open(url, '_blank')
}

export function getDownloadRequestForEditJob (editJob: string) {
  return requests.where('contextId', '==', editJob).orderBy('startTime', 'desc').limit(3)
}
